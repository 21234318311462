import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @justifyContent='center' @alignItems='center' @spacing='1'>\n  <AkLink\n    data-test-serviceAccountList-viewDetailsLink\n    @route='authenticated.dashboard.service-account-details'\n    @model={{@serviceAccount.id}}\n  >\n    <AkIconButton title='{{t \"viewDetails\"}}'>\n      <AnalysisRisk::OverrideDetailsIcon />\n    </AkIconButton>\n  </AkLink>\n\n  <div local-class='divider' />\n\n  <AkIconButton\n    data-test-serviceAccountList-moreOptionBtn\n    {{on 'click' (fn @onMoreMenuClick @serviceAccount)}}\n  >\n    <AkIcon @iconName='more-vert' />\n  </AkIconButton>\n</AkStack>", {"contents":"<AkStack @justifyContent='center' @alignItems='center' @spacing='1'>\n  <AkLink\n    data-test-serviceAccountList-viewDetailsLink\n    @route='authenticated.dashboard.service-account-details'\n    @model={{@serviceAccount.id}}\n  >\n    <AkIconButton title='{{t \"viewDetails\"}}'>\n      <AnalysisRisk::OverrideDetailsIcon />\n    </AkIconButton>\n  </AkLink>\n\n  <div local-class='divider' />\n\n  <AkIconButton\n    data-test-serviceAccountList-moreOptionBtn\n    {{on 'click' (fn @onMoreMenuClick @serviceAccount)}}\n  >\n    <AkIcon @iconName='more-vert' />\n  </AkIconButton>\n</AkStack>","moduleName":"irene/components/organization/service-account/list/action/index.hbs","parseOptions":{"srcName":"irene/components/organization/service-account/list/action/index.hbs"}});
import Component from '@glimmer/component';

import type ServiceAccountModel from 'irene/models/service-account';

export interface OrganizationServiceAccountListExpiryOnSignature {
  Args: {
    serviceAccount: ServiceAccountModel;
    onMoreMenuClick: (
      serviceAccount: ServiceAccountModel,
      event: MouseEvent
    ) => void;
  };
}

export default class OrganizationServiceAccountListExpiryOnComponent extends Component<OrganizationServiceAccountListExpiryOnSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Organization::ServiceAccount::List::Action': typeof OrganizationServiceAccountListExpiryOnComponent;
    'organization/service-account/list/action': typeof OrganizationServiceAccountListExpiryOnComponent;
  }
}
